import { request } from 'http'
import { IData } from '../components/Dashboard/Types'
import { GetDataType, TableDataType } from '../components/ProjectManagement/ProjectSheet/ProjectSheetTypes'
import { GetAccessToken, IRequestData, LoginAuthData } from '../components/Types'
import { ICreateComment } from '../models/create-comment.model'
import * as actions from './actiontypes'
import {
  FETCH_EMPINFO,
  FETCH_USERS,
  FETCH_BACKINFO,
  FETCH_HOLIDAYS,
  FETCH_ASSET,
  FETCH_DESIGNATION,
  FETCH_DESIGNATIONBAND,
  FETCH_EMPLOYMENTTYPE,
  FETCH_SAPERIOD,
  FETCH_TIMING,
  FETCH_BASICINFO,
  FETCH_SR,
  FETCH_BANKINFO,
  FETCH_COUNTRY,
  FETCH_STATE,
  FETCH_ACCOUNTTYPE,
  FETCH_MARITIAL_STATUS,
  FETCH_LEAVE_DETAILS,
  FETCH_QUALIFICATION,
  FETCH_QUALIFICATION_SKILL,
  CREATE_SERVICE_REQUEST,
  FETCH_DEPARTMENT_LIST,
  FETCH_ISSUE_TYPE_LIST,
  SEND_LOAN_REQUEST,
  USER_INFO,
  HOME_PAGE_INFO,
  FETCH_USER_BY_ID,
  FETCH_REF_BY_ID,
  FETCH_COMPENSATION_DETAILS,
  FETCH_LOAN_DETAILS,
  FETCH_EMPLOYEE_DETAILS,
  ADD_NEW_COMMENT,
  FETCH_LOAN_INSTALLMENT_DATA,
  GET_TIMESHEET,
  GET_PAY_SLIP,
  FETCH_DRS_DATA,
  FETCH_SUB_DRS_DATA,
  GET_LEAVE_TIMESHEET,
  GET_LEAVE_DATA,
  ACCEPT_LEAVE_DATA,
  REJECT_LEAVE_DATA,
  FETCH_PROJECT_DATA,
  FETCH_HIGHLIGHTS,
  STATUS_SUMMARY,
  FETCH_ATTENDANCE,
  FETCH_ASSIGNED_SR,
} from './actiontypes'
import { EmpHistoryDataTypes, EmpManagerDataTypes } from '../components/EmployeeInfo/Info'

const {
  SUCCESS,
  REQUEST,
  FAILURE,
  RESET,
  GET_USER_DATA,
  LOGIN,
  LOGOUT,
  GET_ACCESS_TOKEN,
  LOGIN_USER,
  action,
} = actions

// Bellow are the Action Objects
export const getUserData = {
  request: () => action(GET_USER_DATA[REQUEST]),
  success: (response: IData) => action(GET_USER_DATA[SUCCESS], { response }),
  failure: () => action(GET_USER_DATA[FAILURE]),
  reset: () => action(GET_USER_DATA[RESET], {}),
}

export const getUserAuthentication = {
  request: (data: LoginAuthData) => action(LOGIN[REQUEST], { data }),
  success: (response: LoginAuthData) => action(LOGIN[SUCCESS], { response }),
  failure: () => action(LOGIN[FAILURE]),
  reset: () => action(LOGIN[RESET], {}),
}

//Action for renewing access token
export const renewAccessToken = {
  request: (data: GetAccessToken) => action(GET_ACCESS_TOKEN[REQUEST], { data }),
  success: (response: GetAccessToken) => action(GET_ACCESS_TOKEN[SUCCESS], { response }),
  failure: () => action(GET_ACCESS_TOKEN[FAILURE]),
  reset: () => action(GET_ACCESS_TOKEN[RESET], {}),
}

export const getUserLogout = {
  request: () => action(LOGOUT[REQUEST]),
  success: () => action(LOGOUT[SUCCESS]),
  failure: () => action(LOGOUT[FAILURE]),
  reset: () => action(LOGOUT[RESET], {}),
}

export const getUserLoginUser = {
  request: (data: any) => action(LOGIN_USER[REQUEST], { data }),
  success: (response: any) => action(LOGIN_USER[SUCCESS], { response }),
  failure: () => action(LOGIN_USER[FAILURE]),
  reset: () => action(LOGIN_USER[RESET], {}),
}

// Bellow are the Action Objects
export const fetchUserData = {
  request: () => action(FETCH_USERS[REQUEST]),
  success: (response: IData) => action(FETCH_USERS[SUCCESS], { response }),
  failure: () => action(FETCH_USERS[FAILURE]),
  reset: () => action(FETCH_USERS[RESET], {}),
}

export const fetchBackInfo = {
  request: () => action(FETCH_BACKINFO[REQUEST]),
  success: (response: IData) => action(FETCH_BACKINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BACKINFO[FAILURE]),
  reset: () => action(FETCH_BACKINFO[RESET], {}),
}

export const fetchHolidays = {
  request: () => action(FETCH_HOLIDAYS[REQUEST]),
  success: (response: IData) => action(FETCH_HOLIDAYS[SUCCESS], { response }),
  failure: () => action(FETCH_HOLIDAYS[FAILURE]),
  reset: () => action(FETCH_HOLIDAYS[RESET], {}),
}

export const fetchHighlights = {
  request: () => action(FETCH_HIGHLIGHTS[REQUEST]),
  success: (response: IData) => action(FETCH_HIGHLIGHTS[SUCCESS], { response }),
  failure: () => action(FETCH_HIGHLIGHTS[FAILURE]),
  reset: () => action(FETCH_HIGHLIGHTS[RESET], {}),
}

export const fetchAsset = {
  request: () => action(FETCH_ASSET[REQUEST]),
  success: (response: IData) => action(FETCH_ASSET[SUCCESS], { response }),
  failure: () => action(FETCH_ASSET[FAILURE]),
  reset: () => action(FETCH_ASSET[RESET], {}),
}

export const fetchEmpInfo = {
  request: () => action(FETCH_EMPINFO[REQUEST]),
  success: (response: IData) => action(FETCH_EMPINFO[SUCCESS], { response }),
  failure: () => action(FETCH_EMPINFO[FAILURE]),
  reset: () => action(FETCH_EMPINFO[RESET], {}),
}

export const fetchDesignation = {
  request: () => action(FETCH_DESIGNATION[REQUEST]),
  success: (response: IData) => action(FETCH_DESIGNATION[SUCCESS], { response }),
  failure: () => action(FETCH_DESIGNATION[FAILURE]),
  reset: () => action(FETCH_DESIGNATION[RESET], {}),
}

export const fetchDesignationBand = {
  request: () => action(FETCH_DESIGNATIONBAND[REQUEST]),
  success: (response: IData) => action(FETCH_DESIGNATIONBAND[SUCCESS], { response }),
  failure: () => action(FETCH_DESIGNATIONBAND[FAILURE]),
  reset: () => action(FETCH_DESIGNATIONBAND[RESET], {}),
}

export const fetchEmplymentType = {
  request: () => action(FETCH_EMPLOYMENTTYPE[REQUEST]),
  success: (response: IData) => action(FETCH_EMPLOYMENTTYPE[SUCCESS], { response }),
  failure: () => action(FETCH_EMPLOYMENTTYPE[FAILURE]),
  reset: () => action(FETCH_EMPLOYMENTTYPE[RESET], {}),
}

export const fetchSAPeriod = {
  request: () => action(FETCH_SAPERIOD[REQUEST]),
  success: (response: IData) => action(FETCH_SAPERIOD[SUCCESS], { response }),
  failure: () => action(FETCH_SAPERIOD[FAILURE]),
  reset: () => action(FETCH_SAPERIOD[RESET], {}),
}

export const fetchTiming = {
  request: () => action(FETCH_TIMING[REQUEST]),
  success: (response: IData) => action(FETCH_TIMING[SUCCESS], { response }),
  failure: () => action(FETCH_TIMING[FAILURE]),
  reset: () => action(FETCH_TIMING[RESET], {}),
}

export const fetchBasicInfo = {
  request: (data: any) => action(FETCH_BASICINFO[REQUEST], { data }),
  success: (response: IData) => action(FETCH_BASICINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BASICINFO[FAILURE]),
  reset: () => action(FETCH_BASICINFO[RESET], {}),
}

export const getLoanInstallment = {
  request: (data: any) => action(FETCH_LOAN_INSTALLMENT_DATA[REQUEST], { data }),
  success: (response: IData) => action(FETCH_LOAN_INSTALLMENT_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_LOAN_INSTALLMENT_DATA[FAILURE]),
  reset: () => action(FETCH_LOAN_INSTALLMENT_DATA[RESET], {}),
}

export const getDrsData = {
  request: () => action(FETCH_DRS_DATA[REQUEST]),
  success: (response: IData) => action(FETCH_DRS_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_DRS_DATA[FAILURE]),
  reset: () => action(FETCH_DRS_DATA[RESET], {}),
}

export const getSubDrsData = {
  request: (data: { id: number }) => action(FETCH_SUB_DRS_DATA[REQUEST], data),
  success: (response: IData) => action(FETCH_SUB_DRS_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_SUB_DRS_DATA[FAILURE]),
  reset: () => action(FETCH_SUB_DRS_DATA[RESET], {}),
}

export const fetchSR = {
  request: (data: any) => action(FETCH_SR[REQUEST], { data }),
  success: (response: IData) => action(FETCH_SR[SUCCESS], { response }),
  failure: () => action(FETCH_SR[FAILURE]),
  reset: () => action(FETCH_SR[RESET], {}),
}

export const fetchAssignedSR = {
  request: (data: any) => action(FETCH_ASSIGNED_SR[REQUEST], { data }),
  success: (response: IData) => action(FETCH_ASSIGNED_SR[SUCCESS], { response }),
  failure: () => action(FETCH_ASSIGNED_SR[FAILURE]),
  reset: () => action(FETCH_ASSIGNED_SR[RESET], {}),
}

export const getProjectData = {
  request: () => action(FETCH_PROJECT_DATA[REQUEST]),
  success: (response: IData) => action(FETCH_PROJECT_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_PROJECT_DATA[FAILURE]),
  reset: () => action(FETCH_PROJECT_DATA[RESET], {}),
}

export const fetchBankInfo = {
  request: () => action(FETCH_BANKINFO[REQUEST]),
  success: (response: IData) => action(FETCH_BANKINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BANKINFO[FAILURE]),
  reset: () => action(FETCH_BANKINFO[RESET], {}),
}

export const fetchCountry = {
  request: () => action(FETCH_COUNTRY[REQUEST]),
  success: (response: IData) => action(FETCH_COUNTRY[SUCCESS], { response }),
  failure: () => action(FETCH_COUNTRY[FAILURE]),
  reset: () => action(FETCH_COUNTRY[RESET], {}),
}

export const fetchState = {
  request: () => action(FETCH_STATE[REQUEST]),
  success: (response: IData) => action(FETCH_STATE[SUCCESS], { response }),
  failure: () => action(FETCH_STATE[FAILURE]),
  reset: () => action(FETCH_STATE[RESET], {}),
}

export const fetchAccountType = {
  request: () => action(FETCH_ACCOUNTTYPE[REQUEST]),
  success: (response: IData) => action(FETCH_ACCOUNTTYPE[SUCCESS], { response }),
  failure: () => action(FETCH_ACCOUNTTYPE[FAILURE]),
  reset: () => action(FETCH_ACCOUNTTYPE[RESET], {}),
}

export const fetchMaritialStatus = {
  request: () => action(FETCH_MARITIAL_STATUS[REQUEST]),
  success: (response: IData) => action(FETCH_MARITIAL_STATUS[SUCCESS], { response }),
  failure: () => action(FETCH_MARITIAL_STATUS[FAILURE]),
  reset: () => action(FETCH_MARITIAL_STATUS[RESET], {}),
}

export const fetchLeaveDetails = {
  request: (data: any) => action(FETCH_LEAVE_DETAILS[REQUEST], { data }),
  success: (response: IData) => action(FETCH_LEAVE_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_LEAVE_DETAILS[FAILURE]),
  reset: () => action(FETCH_LEAVE_DETAILS[RESET], {}),
}

export const fetchDepartmentList = {
  request: () => action(FETCH_DEPARTMENT_LIST[REQUEST]),
  success: (response: any) => action(FETCH_DEPARTMENT_LIST[SUCCESS], { response }),
  failure: () => action(FETCH_DEPARTMENT_LIST[FAILURE]),
  reset: () => action(FETCH_DEPARTMENT_LIST[RESET], {}),
}

export const fetchQualification = {
  request: () => action(FETCH_QUALIFICATION[REQUEST]),
  success: (response: IData) => action(FETCH_QUALIFICATION[SUCCESS], { response }),
  failure: () => action(FETCH_QUALIFICATION[FAILURE]),
  reset: () => action(FETCH_QUALIFICATION[RESET], {}),
}

export const fetchQualificationSkill = {
  request: () => action(FETCH_QUALIFICATION_SKILL[REQUEST]),
  success: (response: IData) => action(FETCH_QUALIFICATION_SKILL[SUCCESS], { response }),
  failure: () => action(FETCH_QUALIFICATION_SKILL[FAILURE]),
  reset: () => action(FETCH_QUALIFICATION_SKILL[RESET], {}),
}

export const createServiceRequest = {
  request: (data: any) => action(CREATE_SERVICE_REQUEST[REQUEST], { data }),
  success: (response: IRequestData) => action(CREATE_SERVICE_REQUEST[SUCCESS], { response }),
  failure: () => action(CREATE_SERVICE_REQUEST[FAILURE]),
  reset: () => action(CREATE_SERVICE_REQUEST[RESET], {}),
}

export const getIssueTypeList = {
  request: (data: any) => action(FETCH_ISSUE_TYPE_LIST[REQUEST], { data }),
  success: (response: any) => action(FETCH_ISSUE_TYPE_LIST[SUCCESS], { response }),
  failure: () => action(FETCH_ISSUE_TYPE_LIST[FAILURE]),
  reset: () => action(FETCH_ISSUE_TYPE_LIST[RESET], {}),
}

export const getManagerDataById = {
  request: (data: any) => action(FETCH_USER_BY_ID[REQUEST], { data }),
  success: (response: any) => action(FETCH_USER_BY_ID[SUCCESS], { response }),
  failure: () => action(FETCH_USER_BY_ID[FAILURE]),
  reset: () => action(FETCH_USER_BY_ID[RESET], {}),
}

export const getCompensationDetails = {
  request: (data: any) => action(FETCH_COMPENSATION_DETAILS[REQUEST], { data }),
  success: (response: any) => action(FETCH_COMPENSATION_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_COMPENSATION_DETAILS[FAILURE]),
  reset: () => action(FETCH_COMPENSATION_DETAILS[RESET], {}),
}

export const getLoanDetails = {
  request: () => action(FETCH_LOAN_DETAILS[REQUEST], {}),
  success: (response: any) => action(FETCH_LOAN_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_LOAN_DETAILS[FAILURE]),
  reset: () => action(FETCH_LOAN_DETAILS[RESET], {}),
}

export const getRefDataById = {
  request: (data: any) => action(FETCH_REF_BY_ID[REQUEST], { data }),
  success: (response: any) => action(FETCH_REF_BY_ID[SUCCESS], { response }),
  failure: () => action(FETCH_REF_BY_ID[FAILURE]),
  reset: () => action(FETCH_REF_BY_ID[RESET], {}),
}

export const getUserDetails = {
  request: () => action(USER_INFO[REQUEST], {}),
  success: (response: any) => action(USER_INFO[SUCCESS], { response }),
  failure: () => action(USER_INFO[FAILURE]),
  reset: () => action(USER_INFO[RESET], {}),
}

export const getHomePageDetails = {
  request: () => action(HOME_PAGE_INFO[REQUEST], {}),
  success: (response: any) => action(HOME_PAGE_INFO[SUCCESS], { response }),
  failure: () => action(HOME_PAGE_INFO[FAILURE]),
  reset: () => action(HOME_PAGE_INFO[RESET], {}),
}

export const getStatusSummary = {
  request: (data: any) => action(STATUS_SUMMARY[REQUEST], { data }),
  success: (response: any) => action(STATUS_SUMMARY[SUCCESS], { response }),
  failure: () => action(STATUS_SUMMARY[FAILURE]),
  reset: () => action(STATUS_SUMMARY[RESET], {}),
}

export const sendLoanRequest = {
  request: (data: any) => action(SEND_LOAN_REQUEST[REQUEST], { data }),
  success: (response: any) => action(SEND_LOAN_REQUEST[SUCCESS], { response }),
  failure: () => action(SEND_LOAN_REQUEST[FAILURE]),
  reset: () => action(SEND_LOAN_REQUEST[RESET], {}),
}

// To fetch all employee details
export const getAllEmployees = {
  request: () => action(FETCH_EMPLOYEE_DETAILS[REQUEST], {}),
  success: (response: any) => action(FETCH_EMPLOYEE_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_EMPLOYEE_DETAILS[FAILURE]),
  reset: () => action(FETCH_EMPLOYEE_DETAILS[RESET], {}),
}

export const addNewComment = {
  request: (data: ICreateComment) => action(ADD_NEW_COMMENT[REQUEST], { data }),
  success: (response: any) => action(ADD_NEW_COMMENT[SUCCESS], { response }),
  failure: () => action(ADD_NEW_COMMENT[FAILURE]),
  reset: () => action(ADD_NEW_COMMENT[RESET], {}),
}

export const getTimesheet = {
  request: () => action(GET_TIMESHEET[REQUEST], {}),
  success: (response: any) => action(GET_TIMESHEET[SUCCESS], { response }),
  failure: () => action(GET_TIMESHEET[FAILURE]),
  reset: () => action(GET_TIMESHEET[RESET], {}),
}

export const getLeaveTimesheets = {
  request: () => action(GET_LEAVE_TIMESHEET[REQUEST], {}),
  success: (response: any) => action(GET_LEAVE_TIMESHEET[SUCCESS], { response }),
  failure: () => action(GET_LEAVE_TIMESHEET[FAILURE]),
  reset: () => action(GET_LEAVE_TIMESHEET[RESET], {}),
}

export const getLeaveData = {
  request: (data: any) => action(GET_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(GET_LEAVE_DATA[FAILURE]),
  reset: () => action(GET_LEAVE_DATA[RESET], {}),
}

export const acceptLeaveData = {
  request: (data: any) => action(ACCEPT_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(ACCEPT_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(ACCEPT_LEAVE_DATA[FAILURE]),
  reset: () => action(ACCEPT_LEAVE_DATA[RESET], {}),
}

export const rejectLeaveData = {
  request: (data: any) => action(REJECT_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(REJECT_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(REJECT_LEAVE_DATA[FAILURE]),
  reset: () => action(REJECT_LEAVE_DATA[RESET], {}),
}

export const getPaySlip = {
  request: (data: any) => action(GET_PAY_SLIP[REQUEST], { data }),
  success: (response: any) => action(GET_PAY_SLIP[SUCCESS], { response }),
  failure: () => action(GET_PAY_SLIP[FAILURE]),
  reset: () => action(GET_PAY_SLIP[RESET], {}),
}

export const getAttendanceDetail = {
  request: (data: any) => action(FETCH_ATTENDANCE[REQUEST], data),
  success: (response: IData) => action(FETCH_ATTENDANCE[SUCCESS], { response }),
  failure: () => action(FETCH_ATTENDANCE[FAILURE]),
  reset: () => action(FETCH_ATTENDANCE[RESET], {}),
}

export const getAttendanceTimesheet = {
  request: (data: any) => action(actions.FETCH_ATTENDANCE_TIMESHEET[REQUEST], data),
  success: (response: IData) => action(actions.FETCH_ATTENDANCE_TIMESHEET[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ATTENDANCE_TIMESHEET[FAILURE]),
  reset: () => action(actions.FETCH_ATTENDANCE_TIMESHEET[RESET], {}),
}

export const getRCAs = {
  request: (data: any) => action(actions.FETCH_RCA[REQUEST], data),
  success: (response: IData) => action(actions.FETCH_RCA[SUCCESS], { response }),
  failure: () => action(actions.FETCH_RCA[FAILURE]),
  reset: () => action(actions.FETCH_RCA[RESET], {}),
}

export const getIDSRs = {
  request: (data: any) => action(actions.FETCH_IDSR[REQUEST], data),
  success: (response: any) => action(actions.FETCH_IDSR[SUCCESS], { response }),
  failure: () => action(actions.FETCH_IDSR[FAILURE]),
  reset: () => action(actions.FETCH_IDSR[RESET], {}),
}

export const getPlans = {
  request: (data: any) => action(actions.FETCH_PLANS_FOR_THE_DAY[REQUEST], data),
  success: (response: any) => action(actions.FETCH_PLANS_FOR_THE_DAY[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PLANS_FOR_THE_DAY[FAILURE]),
  reset: () => action(actions.FETCH_PLANS_FOR_THE_DAY[RESET], {}),
}

export const getStatusType = {
  request: () => action(actions.STATUS_TYPE[REQUEST], {}),
  success: (response: any) => action(actions.STATUS_TYPE[SUCCESS], { response }),
  failure: () => action(actions.STATUS_TYPE[FAILURE]),
  reset: () => action(actions.STATUS_TYPE[RESET], {}),
}

export const getTaskStatus = {
  request: () => action(actions.TASK_STATUS[REQUEST], {}),
  success: (response: any) => action(actions.TASK_STATUS[SUCCESS], { response }),
  failure: () => action(actions.TASK_STATUS[FAILURE]),
  reset: () => action(actions.TASK_STATUS[RESET], {}),
}

export const createNewRCA = {
  request: (data: any) => action(actions.CREATE_RCA[REQUEST], data),
  success: (response: IData) => action(actions.CREATE_RCA[SUCCESS], { response }),
  failure: () => action(actions.CREATE_RCA[FAILURE]),
  reset: () => action(actions.CREATE_RCA[RESET], {}),
}

export const createNewIDSR = {
  request: (data: any) => action(actions.CREATE_IDSR[REQUEST], data),
  success: (response: IData) => action(actions.CREATE_IDSR[SUCCESS], { response }),
  failure: () => action(actions.CREATE_IDSR[FAILURE]),
  reset: () => action(actions.CREATE_IDSR[RESET], {}),
}

export const createNewPlanForTheDay = {
  request: (data: any) => action(actions.CREATE_PLAN_FOR_THE_DAY[REQUEST], data),
  success: (response: IData) => action(actions.CREATE_PLAN_FOR_THE_DAY[SUCCESS], { response }),
  failure: () => action(actions.CREATE_PLAN_FOR_THE_DAY[FAILURE]),
  reset: () => action(actions.CREATE_PLAN_FOR_THE_DAY[RESET], {}),
}

export const fetchAsignedRequest = {
  request: (data: any) => action(actions.FETCH_ASSIGNED_REQUEST[REQUEST], data),
  success: (response: any) => action(actions.FETCH_ASSIGNED_REQUEST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ASSIGNED_REQUEST[FAILURE]),
  reset: () => action(actions.FETCH_ASSIGNED_REQUEST[RESET], {}),
}

export const fetchUpdatedServiceRequest = {
  request: (data: any) => action(actions.FETCH_UPDATED_SERVICE_REQUEST[REQUEST], data),
  success: (response: any) => action(actions.FETCH_UPDATED_SERVICE_REQUEST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_UPDATED_SERVICE_REQUEST[FAILURE]),
  reset: () => action(actions.FETCH_UPDATED_SERVICE_REQUEST[RESET], {}),
}

export const fetchDownloadableURL = {
  request: (data: any) => action(actions.FETCH_DOWNLOADABLE_URL[REQUEST], data),
  success: (response: any) => action(actions.FETCH_DOWNLOADABLE_URL[SUCCESS], { response }),
  failure: () => action(actions.FETCH_DOWNLOADABLE_URL[FAILURE]),
  reset: () => action(actions.FETCH_DOWNLOADABLE_URL[RESET], {}),
}

export const fetchDocURL = {
  request: (data: any) => action(actions.FETCH_DOC_URL[REQUEST], data),
  success: (response: any) => action(actions.FETCH_DOC_URL[SUCCESS], { response }),
  failure: () => action(actions.FETCH_DOC_URL[FAILURE]),
  reset: () => action(actions.FETCH_DOC_URL[RESET], {}),
}

export const managerViewData = {
  request: (data: any) => action(actions.MANAGER_VIEW_DATA[REQUEST], data),
  success: (response: any) => action(actions.MANAGER_VIEW_DATA[SUCCESS], { response }),
  failure: () => action(actions.MANAGER_VIEW_DATA[FAILURE]),
  reset: () => action(actions.MANAGER_VIEW_DATA[RESET], {}),
}

export const fetchProjectsName = {
  request: (data: any) => action(actions.FETCH_PROJECTS_NAME[REQUEST], data),
  success: (response: any) => action(actions.FETCH_PROJECTS_NAME[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECTS_NAME[FAILURE]),
  reset: () => action(actions.FETCH_PROJECTS_NAME[RESET], {}),
}
export const projectGraphData = {
  request: (data: any) => action(actions.PROJECT_GRAPH_DATA[REQUEST], data),
  success: (response: any) => action(actions.PROJECT_GRAPH_DATA[SUCCESS], { response }),
  failure: () => action(actions.PROJECT_GRAPH_DATA[FAILURE]),
  reset: () => action(actions.PROJECT_GRAPH_DATA[RESET], {}),
}

export const desgnationGraphData = {
  request : (data: any) => action(actions.DESIGNATION_GRAPH_DATA[REQUEST],data),
  success: (response: any) => action(actions.DESIGNATION_GRAPH_DATA[SUCCESS], { response }),
  failure: () => action(actions.DESIGNATION_GRAPH_DATA[FAILURE]),
  reset: () => action(actions.DESIGNATION_GRAPH_DATA[RESET], {}),
}

export const designationList = {
  request: () => action(actions.DESIGNATION_LIST[REQUEST]),
  success: (response: any) => action(actions.DESIGNATION_LIST[SUCCESS], { response }),
  failure: () => action(actions.DESIGNATION_LIST[FAILURE]),
  reset: () => action(actions.DESIGNATION_LIST[RESET], {}),
}

export const projectDomain = {
  request: () => action(actions.FETCH_PROJECT_DOMAIN[REQUEST]),
  success: (response: any) => action(actions.FETCH_PROJECT_DOMAIN[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_DOMAIN[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_DOMAIN[RESET], {}),
}

export const projectSource = {
  request: () => action(actions.FETCH_PROJECT_SOURCE[REQUEST]),
  success: (response: any) => action(actions.FETCH_PROJECT_SOURCE[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_SOURCE[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_SOURCE[RESET], {}),
}

export const projectTypes = {
  request: (data: any) => action(actions.FETCH_PROJECT_TYPES[REQUEST], data),
  success: (response: any) => action(actions.FETCH_PROJECT_TYPES[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_TYPES[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_TYPES[RESET], {}),
}
export const fetchTechnoligies = {
  request: () => action(actions.FETCH_TECHNOLOGIES[REQUEST]),
  success: (response: any) => action(actions.FETCH_TECHNOLOGIES[SUCCESS], { response }),
  failure: () => action(actions.FETCH_TECHNOLOGIES[FAILURE]),
  reset: () => action(actions.FETCH_TECHNOLOGIES[RESET], {}),
}
export const fetchProjectLocation = {
  request: () => action(actions.FETCH_PROJECT_LOCATION[REQUEST]),
  success: (response: any) => action(actions.FETCH_PROJECT_LOCATION[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_LOCATION[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_LOCATION[RESET], {}),
}

export const addNewProject = {
  request: (data: any) => action(actions.ADD_NEW_PROJECT[REQUEST], data),
  success: (response: any) => action(actions.ADD_NEW_PROJECT[SUCCESS], { response }),
  failure: () => action(actions.ADD_NEW_PROJECT[FAILURE]),
  reset: () => action(actions.ADD_NEW_PROJECT[RESET], {}),
}

export const fetchDeletingProject = {
  request: (data: any) => action(actions.DELETE_PROJECT[REQUEST], data),
  success: (response: any) => action(actions.DELETE_PROJECT[SUCCESS], { response }),
  failure: () => action(actions.DELETE_PROJECT[FAILURE]),
  reset: () => action(actions.DELETE_PROJECT[RESET], {}),
}

export const fetchProjectCustomers = {
  request: () => action(actions.FETCH_PROJECT_CUSTOMERS[REQUEST]),
  success: (response: any) => action(actions.FETCH_PROJECT_CUSTOMERS[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_CUSTOMERS[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_CUSTOMERS[RESET], {}),
}

export const fetchMandateType = {
  request: (data: object) => action(actions.FETCH_MANDATE_TYPE[REQUEST], data),
  success: (response: string) => action(actions.FETCH_MANDATE_TYPE[SUCCESS], { response }),
  failure: () => action(actions.FETCH_MANDATE_TYPE[FAILURE]),
  reset: () => action(actions.FETCH_MANDATE_TYPE[RESET], {}),
}

export const fetchRestartedProject = {
  request: (data: any) => action(actions.RESTART_PROJECT[REQUEST], data),
  success: (response: any) => action(actions.RESTART_PROJECT[SUCCESS], { response }),
  failure: () => action(actions.RESTART_PROJECT[FAILURE]),
  reset: () => action(actions.RESTART_PROJECT[RESET], {}),
}

export const fetchProjectDetails = {
  request: (data: any) => action(actions.FETCH_PROJECT_DETAILS[REQUEST], data),
  success: (response: any) => action(actions.FETCH_PROJECT_DETAILS[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_DETAILS[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_DETAILS[RESET], {}),
}

export const fetchWorkingEmp = {
  request: () => action(actions.FETCH_WORKING_EMP[REQUEST]),
  success: (response: any) => action(actions.FETCH_WORKING_EMP[SUCCESS], { response }),
  failure: () => action(actions.FETCH_WORKING_EMP[FAILURE]),
  reset: () => action(actions.FETCH_WORKING_EMP[RESET], {}),
}

export const fetchEmpBasedOnRoles = {
  request: (data: any) => action(actions.ADD_EMP_BASED_ON_ROLE[REQUEST], data),
  success: (response: any) => action(actions.ADD_EMP_BASED_ON_ROLE[SUCCESS], { response }),
  failure: () => action(actions.ADD_EMP_BASED_ON_ROLE[FAILURE]),
  reset: () => action(actions.ADD_EMP_BASED_ON_ROLE[RESET], {}),
}

export const fetchUpdatedProject = {
  request: (data: any) => action(actions.UPDATE_PROJECT[REQUEST], data),
  success: (response: any) => action(actions.UPDATE_PROJECT[SUCCESS], { response }),
  failure: () => action(actions.UPDATE_PROJECT[FAILURE]),
  reset: () => action(actions.UPDATE_PROJECT[RESET], {}),
}

export const fetchDeletedRecords = {
  request: (data: any) => action(actions.DELETE_EMP_BASED_ON_ROLE[REQUEST], data),
  success: (response: any) => action(actions.DELETE_EMP_BASED_ON_ROLE[SUCCESS], { response }),
  failure: () => action(actions.DELETE_EMP_BASED_ON_ROLE[FAILURE]),
  reset: () => action(actions.DELETE_EMP_BASED_ON_ROLE[RESET], {}),
}

export const fetchEditedProject = {
  request: (data: any) => action(actions.EDIT_PROJECT[REQUEST], data),
  success: (response: any) => action(actions.EDIT_PROJECT[SUCCESS], { response }),
  failure: () => action(actions.EDIT_PROJECT[FAILURE]),
  reset: () => action(actions.EDIT_PROJECT[RESET], {}),
}

export const fetchDesignations = {
  request: () => action(actions.ORGANIZATION_DESIGNATION[REQUEST]),
  success: (response: any) => action(actions.ORGANIZATION_DESIGNATION[SUCCESS], { response }),
  failure: () => action(actions.ORGANIZATION_DESIGNATION[FAILURE]),
  reset: () => action(actions.ORGANIZATION_DESIGNATION[RESET], {}),

}

export const fetchOrganisationDesignationData = {
  request: (data:{hierarchyName:string}) => action(actions.ORGANISATION_DESIGNATION_DATA[REQUEST],data),
  success: (response: any) => action(actions.ORGANISATION_DESIGNATION_DATA[SUCCESS], { response }),
  failure: () => action(actions.ORGANISATION_DESIGNATION_DATA[FAILURE]),
  reset: () => action(actions.ORGANISATION_DESIGNATION_DATA[RESET], {}),
}

export const fetchProjectReports = {
  request: (data: GetDataType) => action(actions.FETCH_PROJECT_REPORTS[REQUEST], data),
  success: (response: TableDataType) => action(actions.FETCH_PROJECT_REPORTS[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_REPORTS[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_REPORTS[RESET], {}),
}

export const fetchReports = {
  request: (data: any) => action(actions.FETCH_REPORTS[REQUEST], data),
  success: (response: any) => action(actions.FETCH_REPORTS[SUCCESS], { response }),
  failure: () => action(actions.FETCH_REPORTS[FAILURE]),
  reset: () => action(actions.FETCH_REPORTS[RESET], {}),
}

export const getAllResourceReport = {
  request: (data: { startDate: string; endDate: string; project: string, projectSource: string, location: string, employmentType: string }) => { return action(actions.FETCH_ALL_PROJECT_RESOURCE_REPORT[REQUEST], {data})},
  success: (response: IData) => action(actions.FETCH_ALL_PROJECT_RESOURCE_REPORT[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_PROJECT_RESOURCE_REPORT[FAILURE]),
  reset: () => action(actions.FETCH_ALL_PROJECT_RESOURCE_REPORT[RESET], {}),
}
export const getAllResourceReportDropdownData = {
  request: () => action(actions.PROJECT_RESOURCE_REPORT_DROPDOWN[REQUEST]),
  success: (response: any) => action(actions.PROJECT_RESOURCE_REPORT_DROPDOWN[SUCCESS], { response }),
  failure: () => action(actions.PROJECT_RESOURCE_REPORT_DROPDOWN[FAILURE]),
  reset: () => action(actions.PROJECT_RESOURCE_REPORT_DROPDOWN[RESET], {}),
}

export const fetchProjectManagementReports = {  
  request: (data: any) => action(actions.FETCH_PROJECT_MANAGEMENT_REPORT[REQUEST], {data}),
  success: (response: any) => action(actions.FETCH_PROJECT_MANAGEMENT_REPORT[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_MANAGEMENT_REPORT[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_MANAGEMENT_REPORT[RESET], {}),
}

export const fetchProjectQAReport ={
  request: (data:{data: {endDate:string, startDate:string, page:number, limit:number}}) => action(actions.FETCH_PROJECT_QA_REPORT[REQUEST], data),
  success: (response: string) => action(actions.FETCH_PROJECT_QA_REPORT[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_QA_REPORT[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_QA_REPORT[RESET], {}),
}

export const fetchNonBillableResources = {
  request: () => action (actions.FETCH_NON_BILLABLE_RESOURCES[REQUEST]),
  success: (response: {Employee_id: number[]}) => action(actions.FETCH_NON_BILLABLE_RESOURCES[SUCCESS],{response}),
  failure: () => action(actions.FETCH_NON_BILLABLE_RESOURCES[FAILURE]),
  reset: () => action(actions.FETCH_NON_BILLABLE_RESOURCES[RESET],{}),
}

export const updateNonBillableResource = {
  request: (data: {Employee_id: number[]}) => action(actions.UPDATE_NON_BILLABLE_RESOURCES[REQUEST], { data }),
  success: (response: IRequestData) => action(actions.UPDATE_NON_BILLABLE_RESOURCES[SUCCESS], { response }),
  failure: () => action(actions.UPDATE_NON_BILLABLE_RESOURCES[FAILURE]),
  reset: () => action(actions.UPDATE_NON_BILLABLE_RESOURCES[RESET], {}),
}

export const fetchProjectCustomersList = {
  request: (data: object) => action(actions.FETCH_PROJECT_CUSTOMERS_LIST[REQUEST], data),
  success: (response: string) => action(actions.FETCH_PROJECT_CUSTOMERS_LIST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_PROJECT_CUSTOMERS_LIST[FAILURE]),
  reset: () => action(actions.FETCH_PROJECT_CUSTOMERS_LIST[RESET], {}),
}

export const createProjectCustomers = {
  request: (data: object) => action(actions.CREATE_PROJECT_CUSTOMERS[REQUEST], data),
  success: (response: string) => action(actions.CREATE_PROJECT_CUSTOMERS[SUCCESS], { response }),
  failure: () => action(actions.CREATE_PROJECT_CUSTOMERS[FAILURE]),
  reset: () => action(actions.CREATE_PROJECT_CUSTOMERS[RESET], {}),
}

export const createMandateType = {
  request: (data: object) => action(actions.CREATE_MANDATE_TYPE[REQUEST], data),
  success: (response: string) => action(actions.CREATE_MANDATE_TYPE[SUCCESS], { response }),
  failure: () => action(actions.CREATE_MANDATE_TYPE[FAILURE]),
  reset: () => action(actions.CREATE_MANDATE_TYPE[RESET], {}),
}

export const uploadUserImg = {
  request: (data: object) => action(actions.UPLOAD_USER_IMG[REQUEST], {data}),
  success: (response: string) => action(actions.UPLOAD_USER_IMG[SUCCESS], { response }),
  failure: () => action(actions.UPLOAD_USER_IMG[FAILURE]),
  reset: () => action(actions.UPLOAD_USER_IMG[RESET], {}),
}

export const getUserImage = {
  request: (data: {id:number}) => action(actions.GET_USER_IMAGE[REQUEST],data),
  success: (Response: string) => action(actions.GET_USER_IMAGE[SUCCESS],{Response}),
  failure: () => action(actions.GET_USER_IMAGE[FAILURE]),
  reset: () => action(actions.GET_USER_IMAGE[RESET],{})
}

// CREATE_USER
export const createUser = {
  request: (data: object) => action(actions.CREATE_USER[REQUEST], {data}),
  success: (response: string) => action(actions.CREATE_USER[SUCCESS], { response }),
  failure: () => action(actions.CREATE_USER[FAILURE]),
  reset: () => action(actions.CREATE_USER[RESET], {}),
}

export const updateUser = {
  request: (data: any) =>action(actions.UPDATE_USER[REQUEST], data),
  success: (response: string) => action(actions.UPDATE_USER[SUCCESS], { response }),
  failure: () => action(actions.UPDATE_USER[FAILURE]),
  reset: () => action(actions.UPDATE_USER[RESET], {}),
}

export const deleteUser = {
  request: (data:{data:{id:number}}) => action(actions.DELETE_USER[REQUEST], data),
  success: (response: string) => action(actions.DELETE_USER[SUCCESS], { response }),
  failure: () => action(actions.DELETE_USER[FAILURE]),
  reset: () => action(actions.DELETE_USER[RESET], {}),
}

// FETCH_ALL_ROLE
export const fetchAllRoles = {
  request: () => action(actions.FETCH_ALL_ROLE[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_ROLE[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_ROLE[FAILURE]),
  reset: () => action(actions.FETCH_ALL_ROLE[RESET], {}),
}

// FETCH_ALL_STATE
export const fetchAllStates = {
  request: () => action(actions.FETCH_ALL_STATE[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_STATE[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_STATE[FAILURE]),
  reset: () => action(actions.FETCH_ALL_STATE[RESET], {}),
}

// FETCH_ALL_COUNTRY
export const fetchAllCountries = {
  request: () => action(actions.FETCH_ALL_COUNTRY[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_COUNTRY[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_COUNTRY[FAILURE]),
  reset: () => action(actions.FETCH_ALL_COUNTRY[RESET], {}),
}

// FETCH_ALL_FLOOR
export const fetchAllFloors = {
  request: () => action(actions.FETCH_ALL_FLOOR[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_FLOOR[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_FLOOR[FAILURE]),
  reset: () => action(actions.FETCH_ALL_FLOOR[RESET], {}),
}

// FETCH_ALL_USER_LIST
export const fetchAllUserList = {
  request: (data: object) => action(actions.FETCH_ALL_USER_LIST[REQUEST], data),
  success: (response: string) => action(actions.FETCH_ALL_USER_LIST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_USER_LIST[FAILURE]),
  reset: () => action(actions.FETCH_ALL_USER_LIST[RESET], {}),
}

// FETCH_ALL_WORKSTATION
export const fetchAllWorkstation = {
  request: () => action(actions.FETCH_ALL_WORKSTATION[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_WORKSTATION[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_WORKSTATION[FAILURE]),
  reset: () => action(actions.FETCH_ALL_WORKSTATION[RESET], {})
}

export const fetchAllLocation = {
  request: () => action(actions.FETCH_ALL_LOCATION[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_LOCATION[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_LOCATION[FAILURE]),
  reset: () => action(actions.FETCH_ALL_LOCATION[RESET], {})
}

export const fetchAllClientLocation = {
  request: () => action(actions.FETCH_ALL_CLIENT_LOCATION[REQUEST]),
  success: (response: string) => action(actions.FETCH_ALL_CLIENT_LOCATION[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ALL_CLIENT_LOCATION[FAILURE]),
  reset: () => action(actions.FETCH_ALL_CLIENT_LOCATION[RESET],{})
}

export const deleteProjectCustomer = {
  request: (data:{data:{id:number}}) => action(actions.DELETE_PROJECT_CUSTOMER[REQUEST], data),
  success: (response: string) => action(actions.DELETE_PROJECT_CUSTOMER[SUCCESS], { response }),
  failure: () => action(actions.DELETE_PROJECT_CUSTOMER[FAILURE]),
  reset: () => action(actions.DELETE_PROJECT_CUSTOMER[RESET], {}),
}

export const updateProjectCustomer = {
  request: (data:{data: {id:number, customer_name:string}}) =>action(actions.UPDATE_PROJECT_CUSTOMER[REQUEST], data),
  success: (response: string) => action(actions.UPDATE_PROJECT_CUSTOMER[SUCCESS], { response }),
  failure: () => action(actions.UPDATE_PROJECT_CUSTOMER[FAILURE]),
  reset: () => action(actions.UPDATE_PROJECT_CUSTOMER[RESET], {}),
}

export const updateMandateType = {
  request: (data:{data: {id:number, customer_name:string}}) =>action(actions.UPDATE_MANDATE_TYPE[REQUEST], data),
  success: (response: string) => action(actions.UPDATE_MANDATE_TYPE[SUCCESS], { response }),
  failure: () => action(actions.UPDATE_MANDATE_TYPE[FAILURE]),
  reset: () => action(actions.UPDATE_MANDATE_TYPE[RESET], {}),
}

export const deleteMandateType = {
  request: (data:{data:{id:number}}) => action(actions.DELETE_MANDATE_TYPE[REQUEST], data),
  success: (response: string) => action(actions.DELETE_MANDATE_TYPE[SUCCESS], { response }),
  failure: () => action(actions.DELETE_MANDATE_TYPE[FAILURE]),
  reset: () => action(actions.DELETE_MANDATE_TYPE[RESET], {}),
}

interface NodeProps {
  name: string;
  emp_id: string;
  designation: string;
  imageURL: string
  drs?: NodeProps[];
}
export const peopleBasedOrgChart = {

  request: (data: NodeProps) => action(actions.PEOPLE_BASED_ORG_CHART_DATA[REQUEST], data),
  success: (response: NodeProps) => action(actions.PEOPLE_BASED_ORG_CHART_DATA[SUCCESS], { response }),
  failure: () => action(actions.PEOPLE_BASED_ORG_CHART_DATA[FAILURE]),
  reset: () => action(actions.PEOPLE_BASED_ORG_CHART_DATA[RESET], {}),
}

export const departmentBasedOrgChart = {
  request: (data: any) => action(actions.DEPARTMENT_BASED_ORG_CHART_DATA[REQUEST], data),
  success: (response: any) => action(actions.DEPARTMENT_BASED_ORG_CHART_DATA[SUCCESS], { response }),
  failure: () => action(actions.DEPARTMENT_BASED_ORG_CHART_DATA[FAILURE]),
  reset: () => action(actions.DEPARTMENT_BASED_ORG_CHART_DATA[RESET], {}),
}

export const designationBasedOrgChart = {
  request: (data: NodeProps) => action(actions.DESIGNATION_BASED_ORG_CHART_DATA[REQUEST],data),
  success: (response: NodeProps) => action (actions.DESIGNATION_BASED_ORG_CHART_DATA[SUCCESS], {response}),
  failure: () => action(actions.DESIGNATION_BASED_ORG_CHART_DATA[FAILURE]),
  reset: () => action(actions.DESIGNATION_BASED_ORG_CHART_DATA[RESET],{})
}

export const employeeHistory = {
  request: (data: EmpHistoryDataTypes) => action(actions.EMPLOYEE_HISTORY[REQUEST],data),
  success: (response: EmpHistoryDataTypes) => action (actions.EMPLOYEE_HISTORY[SUCCESS], {response}),
  failure: () => action(actions.EMPLOYEE_HISTORY[FAILURE]),
  reset: () => action(actions.EMPLOYEE_HISTORY[RESET],{})
}

export const managerHistory ={
  request: (data: EmpManagerDataTypes) => action(actions.MANAGER_HISTORY[REQUEST],data),
  success: (Response: EmpManagerDataTypes) => action(actions.MANAGER_HISTORY[SUCCESS],{Response}),
  failure: () => action(actions.MANAGER_HISTORY[FAILURE]),
  reset: () => action(actions.MANAGER_HISTORY[RESET],{})
}
export interface LogoApiResponse {
  type: string
  data: string; 
}

export const companyLogo = {
  request: (data: {id:number}) => action(actions.COMPANY_LOGO[REQUEST],data),
  success: (Response: LogoApiResponse) => action(actions.COMPANY_LOGO[SUCCESS],{Response}),
  failure: () => action(actions.COMPANY_LOGO[FAILURE]),
  reset: () => action(actions.COMPANY_LOGO[RESET],{})
}
export interface Company {
  id: number;
  company_name: string;
}

export interface CompanyIdTypes {
  type:string
  data: Company[];
}

export const companyId = {
  request: () => action(actions. COMPANY_ID[REQUEST]),
  success: (Response: CompanyIdTypes) => action(actions.COMPANY_ID[SUCCESS],{Response}),
  failure: () => action(actions.COMPANY_ID[FAILURE]),
  reset: () => action(actions.COMPANY_ID[RESET],{})
}

export const approvePlanForTheDay = {
  request: (data: {}) => action(actions. APPROVE_PLANFORTHEDAY[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.APPROVE_PLANFORTHEDAY[SUCCESS],{Response}),
  failure: () => action(actions.APPROVE_PLANFORTHEDAY[FAILURE]),
  reset: () => action(actions.APPROVE_PLANFORTHEDAY[RESET],{})
}

export const addCommentTimesheet = {
  request: (data: {}) => action(actions. ADD_COMMENT_TIMESHEET[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.ADD_COMMENT_TIMESHEET[SUCCESS],{Response}),
  failure: () => action(actions.ADD_COMMENT_TIMESHEET[FAILURE]),
  reset: () => action(actions.ADD_COMMENT_TIMESHEET[RESET],{})
}

export const addCommentPlanForTheDay = {
  request: (data: {}) => action(actions. ADD_COMMENT_PLANFORTHEDAY[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.ADD_COMMENT_PLANFORTHEDAY[SUCCESS],{Response}),
  failure: () => action(actions.ADD_COMMENT_PLANFORTHEDAY[FAILURE]),
  reset: () => action(actions.ADD_COMMENT_PLANFORTHEDAY[RESET],{})
}

export const getCommentPLanForTheDay = {
  request: (data: {}) => action(actions. GET_COMMENT_PLANFORTHEDAY[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.GET_COMMENT_PLANFORTHEDAY[SUCCESS],{Response}),
  failure: () => action(actions.GET_COMMENT_PLANFORTHEDAY[FAILURE]),
  reset: () => action(actions.GET_COMMENT_PLANFORTHEDAY[RESET],{})
}

export const getCommentForTimesheet = {
  request: (data: {}) => action(actions. GET_COMMENT_TIMESHEET[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.GET_COMMENT_TIMESHEET[SUCCESS],{Response}),
  failure: () => action(actions.GET_COMMENT_TIMESHEET[FAILURE]),
  reset: () => action(actions.GET_COMMENT_TIMESHEET[RESET],{})
}

export const getPlanForTheDay = {
  request: (data: {}) => action(actions.GET_PLANFORTHEDAY[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.GET_PLANFORTHEDAY[SUCCESS],{Response}),
  failure: () => action(actions.GET_PLANFORTHEDAY[FAILURE]),
  reset: () => action(actions.GET_PLANFORTHEDAY[RESET],{})
}

export const editPlanForTheDay = {
  request: (data: {}) => action(actions.EDIT_PLANFORTHEDAY[REQUEST], data),
  success: (Response: CompanyIdTypes) => action(actions.EDIT_PLANFORTHEDAY[SUCCESS],{Response}),
  failure: () => action(actions.EDIT_PLANFORTHEDAY[FAILURE]),
  reset: () => action(actions.EDIT_PLANFORTHEDAY[RESET],{})
}

export const getDownLoadCsvForMyTeam = {
  request: (data: {}) => action(actions.DOWNLOAD_CSV_FOR_MY_TEAM[REQUEST], data),
  success: (Response: any) => action(actions.DOWNLOAD_CSV_FOR_MY_TEAM[SUCCESS],{Response}),
  failure: () => action(actions.DOWNLOAD_CSV_FOR_MY_TEAM[FAILURE]),
  reset: () => action(actions.DOWNLOAD_CSV_FOR_MY_TEAM[RESET],{})
}

export const getSingleIdsr = {
  request: (data: {}) => action(actions.GET_SINGLE_IDSR[REQUEST], data),
  success: (Response: any) => action(actions.GET_SINGLE_IDSR[SUCCESS],{Response}),
  failure: () => action(actions.GET_SINGLE_IDSR[FAILURE]),
  reset: () => action(actions.GET_SINGLE_IDSR[RESET],{})
}

export const editSingleIdsr = {
  request: (data: {}) => action(actions.EDIT_SINGLE_IDSR[REQUEST], data),
  success: (Response: any) => action(actions.EDIT_SINGLE_IDSR[SUCCESS],{Response}),
  failure: () => action(actions.EDIT_SINGLE_IDSR[FAILURE]),
  reset: () => action(actions.EDIT_SINGLE_IDSR[RESET],{})
}

export const getPayrollForm16 = {
  request: (data:any) => action(actions.PAYROLL_FORM16[REQUEST],data),
  success: (Response: any) => action(actions.PAYROLL_FORM16[SUCCESS],{Response}),
  failure: () => action(actions.PAYROLL_FORM16[FAILURE]),
  reset: () => action(actions.PAYROLL_FORM16[RESET],{})
}

export const getFinancialYear = {
  request: () => action(actions.GET_FINANCIAL_YEAR[REQUEST]),
  success: (Response: any) => action(actions.GET_FINANCIAL_YEAR[SUCCESS],{Response}),
  failure: () => action(actions.GET_FINANCIAL_YEAR[FAILURE]),
  reset: () => action(actions.GET_FINANCIAL_YEAR[RESET],{})
}

export const uploadForm16 = {
  request: (data:{id_user: number, file: string, currentYear: any}) => action(actions.UPLOAD_FORM16[REQUEST], data),
  success: (Response: any) => action(actions.UPLOAD_FORM16[SUCCESS],{Response}),
  failure: () => action(actions.UPLOAD_FORM16[FAILURE]),
  reset: () => action(actions.UPLOAD_FORM16[RESET],{})
}

export const sendMail = {
  request: (data:{userId: number,accountsDepartmentId: number, currentYear: any}) => action(actions.SEND_EMAIL[REQUEST], data),
  success: (Response: any) => action(actions.SEND_EMAIL[SUCCESS],{Response}),
  failure: () => action(actions.SEND_EMAIL[FAILURE]),
  reset: () => action(actions.SEND_EMAIL[RESET],{})
}

export const downloadForm16 = {
  request: (data: any) => action(actions.DOWNLOAD_FORM16[REQUEST], data),
  success: (Response: any) => action(actions.DOWNLOAD_FORM16[SUCCESS],{Response}),
  failure: () => action(actions.DOWNLOAD_FORM16[FAILURE]),
  reset: () => action(actions.DOWNLOAD_FORM16[RESET],{})
}

export const actionTypes = {
  ...actions,
}
